/* eslint-disable */

import { IconButton } from '@material-ui/core';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import React, { useEffect, useState } from 'react';

import { getSharedTrips, removeShareTrip } from '../../../services/dispatch';
import displayNotification from '../../../utils/notification';
import { rideStatus, riseStatus } from '../new-scheduler/constant';
import NotAllowedToRemove from './notAllowedToRemove';
import RemoveConfirmation from './removeConfirmation';
import styles from './styles.module.scss';
import TripsOnMap from './trips-on-googlemap';
import { combineBookingsByAddress } from '../../../lib/common';

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(localizedFormat);

const { host, protocol } = window.location;
const dropOffUrl = `${protocol}//${host}/resources/images/dropoff_pin_same-size.png`;
const pickupOffUrl = `${protocol}//${host}/resources/images/pickup_pin_same-size.png`;
const RemoveIcon = `${protocol}//${host}/resources/images/Remove.svg`;
const SameAddress = 'Same address as above';

const RemoveSharedTrips = ({
  close,
  bookings: propsBookings,
  setSpinner,
  unsetSpinner,
  selectedDate,
  handleNext: next,
  unDispatchedBooking,
  backToDispatchBoard,
  shuttleAnchorLocation,
  setHasChanged: changed,
}) => {
  const [trips, setTrips] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [tripId, setTripId] = useState();
  const [isNotAllowedToRemove, setIsNotAllowedToRemove] = useState(false);
  const [showRemoveConfirmation, setShowRemoveConfirmation] = useState(false);
  const [toRemoveBooking, setToRemoveBooking] = useState();
  const [hasChanged, setHasChanged] = useState(false);

  const allowedToDelete = [1, 2, 5];

  const checkIfSameAddress = (address, index) => {
    // Check if the current address is the first occurrence in the array
    const firstOccurrenceIndex = trips.findIndex((stop) => stop.address === address);

    // If the current index matches the first occurrence, return the original address
    if (firstOccurrenceIndex === index) {
      return address;
    }

    // Otherwise, it's a duplicate, return "SameAddress"
    return SameAddress;
  };

  const removeBooking = async () => {
    setSpinner();
    const newTrips = trips.filter(({ bookings }) => bookings[0].id !== toRemoveBooking.id);
    try {
      const params = {
        trip_id: tripId,
        stop_order: combineBookingsByAddress(newTrips),
        bookings: [toRemoveBooking.id],
        shuttle_anchor_location: shuttleAnchorLocation,
      };

      const resp = await removeShareTrip(params);

      if (resp.status === 'success') {
        setHasChanged(true);
        setTrips(newTrips);
        const booking = propsBookings.filter(({ booking_id }) => booking_id === toRemoveBooking.id);
        if (booking.length > 0) {
          unDispatchedBooking(booking[0]);
        }
      } else {
        if (resp.message.error_code === 'MULTILOADREMOVEBOOKINGERROR') {
          displayNotification('Removing all the bookings in the shared trip is not supported.', 'Error', 'error');
        } else displayNotification(resp.message.message, 'Error', 'error');
      }
    } catch (e) {
      console.error(e);
    }
    setShowRemoveConfirmation(false);
    unsetSpinner();
  };

  const handleClose = () => {
    if (hasChanged) {
      return backToDispatchBoard();
    }
    close();
  };

  const handleRemove = (booking) => {
    if (!allowedToDelete.includes(booking.ride_status)) {
      return setIsNotAllowedToRemove(true);
    }

    //
    setToRemoveBooking(booking);
    setShowRemoveConfirmation(true);
  };

  const handleNext = () => {
    next(trips);
  };

  // structure the booking list to extract
  // bookings on its own trip
  // all address should have one booking on them
  const fixBookingData = (bookingsData) => {
    const newBookings = [];
    bookingsData.forEach((bookings) => {
      if (bookings.bookings.length > 1) {
        bookings.bookings.forEach((booking) => {
          newBookings.push({
            ...bookings,
            bookings: [booking],
            isMoved: true,
          });
        });
        changed(true);
      } else {
        newBookings.push(bookings);
      }
    });

    return newBookings;
  };

  const handleGetSharedTrips = async (trip_id) => {
    setSpinner();
    try {
      const resp = await getSharedTrips({
        trip_id,
      });

      if (resp.status === 'success') {
        const fixDates = resp.data.stop_order.map((data) => {
          data.bookings = data.bookings.map((booking) => {
            //append booking status
            const bkng = propsBookings.filter(({ booking_id }) => booking_id === booking.id);

            if (bkng.length > 0) {
              booking.ride_status = bkng[0].ride_status;
              booking.driver_arrival_time = bkng[0].driver_arrival_time;
              booking.driver_enroute_time = bkng[0].driver_enroute_time;
              booking.driver_arrival_dropoff_time = bkng[0].driver_arrival_dropoff_time;
            }

            // fix date structure
            if (booking.stop_type === 'pickup') {
              booking.scheduled_pickup_time = new Date(dayjs(`${selectedDate} ${booking.scheduled_pickup_time}`));
            } else {
              booking.scheduled_dropoff_time = new Date(dayjs(`${selectedDate} ${booking.scheduled_dropoff_time}`));
            }

            return {
              ...booking,
            };
          });

          return { ...data };
        });
        setBookings(fixDates);
        setTrips(fixBookingData(fixDates));
      } else displayNotification(resp.message.message, 'Error', 'error');
    } catch (e) {
      console.error(e);
    }
    unsetSpinner();
  };

  // const structureBookingsForMap = (bookingsList) => {
  //   const structuredBookings = bookingsList.map((booking) => {
  //     // set tripID id not yet set

  //     return {
  //       id: booking.booking_id,
  //       address: booking.type === 'Pickup' ? booking.pickup_address : booking.dropoff_address,
  //       mixed_type: false,
  //       geoLocation: {
  //         latitude: booking.type === 'Pickup' ? booking.pickup_latitude : booking.dropoff_latitude,
  //         longitude: booking.type === 'Pickup' ? booking.pickup_longitude : booking.dropoff_longitude,
  //       },
  //       account_id: booking.type === 'Pickup' ? booking.pickup_account_id : booking.dropoff_account_id,
  //       place_id: null,
  //       bookings: [
  //         {
  //           stop_type: booking.type === 'Pickup' ? 'pickup' : 'dropoff',
  //           id: booking.booking_id,
  //           passenger_firstname: booking.passenger_firstname,
  //           passenger_lastname: booking.passenger_lastname,
  //           mobility_assistance: booking.mobility_assistance,
  //           scheduled_pickup_time: new Date(dayjs(`${selectedDate} ${booking.scheduled_pickup_time}`)),
  //           scheduled_dropoff_time: new Date(dayjs(`${selectedDate} ${booking.scheduled_dropoff_time}`)),
  //         },
  //       ],
  //     };
  //   });

  //   setBookings(structuredBookings);
  // };

  const getBadgeIndex = (status, arrivalDropoffTime) => {
    const isArrivalAtDo = status === 6 && arrivalDropoffTime !== null;
    const isStatus8 = status === 8;

    if (isArrivalAtDo) {
      return 11;
    }

    if (isStatus8) {
      return 0;
    }

    return status;
  };

  useEffect(() => {
    if (propsBookings) {
      if (!tripId) {
        setTripId(propsBookings?.[0].trip_id);
        handleGetSharedTrips(propsBookings?.[0].trip_id);
      }
    }
  }, [propsBookings]);

  return (
    <>
      <RemoveConfirmation
        show={showRemoveConfirmation}
        close={() => {
          setShowRemoveConfirmation(false);
          setToRemoveBooking(null);
        }}
        remove={removeBooking}
      />
      <NotAllowedToRemove show={isNotAllowedToRemove} close={() => setIsNotAllowedToRemove(false)} />
      <div className={styles.modalContainer}>
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <div className={styles.tripReorderContainer}>
              <div className={styles.mapContainer}>
                <TripsOnMap bookings={bookings} />
              </div>
              <div className={styles.tripsContainer}>
                <div className={styles.header}>
                  <div>Edit Shared Trip</div>
                  <div className={styles.closeIcon}>
                    <IconButton onClick={handleClose}>
                      <svg className="MuiSvgIcon-root" focusable="false" viewBox="0 0 24 24" aria-hidden="true">
                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                      </svg>
                    </IconButton>
                  </div>
                </div>

                <div className={`mt-3 ${styles.tripReorder}`}>
                  <div className={styles.subHeader}>Step 1: Remove booking if needed, or go directly to Step 2.</div>
                  <p className="mt-2">
                    <strong className={`${styles.textError}`}>IMPORTANT NOTE:</strong>&nbsp;if updating the on-going
                    trips in real-time, please be aware that the time elapsed during the adjustment will result outdated
                    information being sent to drivers. It&apos;s your responsibility to communicate with drivers
                    separately about any further changes if needed.
                  </p>
                  <p className="mt-3">
                    All trip status is captured at the moment when this window is opened, and will <strong>NOT</strong>{' '}
                    be updated while this window remains open and your&apos;re making changes.
                  </p>

                  <ul className={`mt-4 ${styles.tripList}`}>
                    {trips.map((trip, index) => {
                      const booking = trip.bookings[0];
                      const isPickup = booking.stop_type === 'pickup';
                      const time = isPickup ? booking.scheduled_pickup_time : booking.scheduled_dropoff_time;
                      const tripTime = new Date(time).toLocaleTimeString([], {
                        hour: '2-digit',
                        minute: '2-digit',
                      });
                      const badgeIndex = booking
                        ? getBadgeIndex(booking.ride_status, booking.driver_arrival_dropoff_time)
                        : 0;
                      const badgeClass = booking ? riseStatus[parseInt(badgeIndex, 10)] : null;
                      const address = checkIfSameAddress(trip.address, index);
                      return (
                        <li
                          key={`${booking.booking_id}${index}${booking.type}`}
                          className={`${styles.tripItem} ${styles.noDrag}`}
                        >
                          <span
                            className={`badge label-table badge-dark mr-2 ${styles.badge} ${badgeClass} ${rideStatus(
                              booking
                            )
                              .replaceAll(' ', '-')
                              .toLowerCase()}`}
                          >
                            {rideStatus(booking)}
                          </span>
                          <div className={styles.tripIcon}>
                            <img src={isPickup ? pickupOffUrl : dropOffUrl} alt="tripIcon" />
                          </div>
                          <div className={styles.tripDetails}>
                            <div className={`${styles.tripName} ${address === SameAddress && styles.sameAddress}`}>
                              {address}
                            </div>
                            <div className={styles.tripAddress}>
                              {tripTime} {booking.passenger_firstname} {booking.passenger_lastname}{' '}
                              {booking.mobility_assistance.length
                                ? booking.mobility_assistance.map((mob) => `, ${mob}`)
                                : ''}
                            </div>
                          </div>
                          <div className={`${styles.removeIcon} mr-0 ml-1`} onClick={() => handleRemove(booking)}>
                            <img src={RemoveIcon} alt="remove" />
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                  <div className="mt-4">
                    <button className="btn btn-secondary" onClick={handleClose}>
                      Cancel
                    </button>
                    <button className="btn btn-primary" style={{ float: 'right' }} onClick={handleNext}>
                      Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RemoveSharedTrips;
