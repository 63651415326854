/* eslint-disable camelcase */
import parse from '../_parse';

export async function fetchNEMList(param) {
  try {
    const lists = await parse.Cloud.run('v1_webapp_get_imported_prebookings', param);
    return lists;
  } catch (error) {
    console.log('fetchNEMList() = ', JSON.stringify(error));
    return error;
  }
}

export async function fetchNEMInfoByID(param) {
  try {
    const response = await parse.Cloud.run('v2_webapp_get_booking_info_by_id', param);
    // console.log(JSON.stringify(response));
    return response;
  } catch (error) {
    console.log(JSON.stringify(error));
    return error;
  }
}
