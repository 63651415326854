/* eslint-disable */

import React, { useEffect, useState } from 'react';
import TripReorder from './trip-order';
import dayjs from 'dayjs';
import { getOptimizedMultipleFlexTrip, getOptimizedRouteTravelData } from '../../../services/dispatch';
import OptimizeTrip from './optimize-trip';
import { combineBookingsByAddress } from '../../../lib/common';

const MultiLoad = ({
  updateBookingAfterSharedRide,
  programId,
  bookings,
  close,
  driver,
  setSpinner,
  unsetSpinner,
  vehicleTrips,
  dispatchDate,
}) => {
  const [view, setView] = useState('');
  const [stopOrder, setStopOrder] = useState([]);
  const [anchorLocation, setAnchorLocation] = useState();
  const [optimizedTime, setOptimizedTime] = useState([]);
  const [hasChanged, setHasChange] = useState(false);

  const handleClose = () => {
    setView('');
    close();
  };

  const lookForTripBookings = () => {
    // Function to parse time with today's date context
    const parseTime = (timeString) => {
      return dayjs(dayjs().format('YYYY-MM-DD') + ' ' + timeString, ['YYYY-MM-DD h:mm A', 'YYYY-MM-DD H:mm']);
    };

    // Find the earliest pickup_time in var2
    const earliestVar2Time = bookings.reduce((earliest, item) => {
      const parsedTime = parseTime(item.pickup_time);
      return !earliest || parsedTime.isBefore(earliest) ? parsedTime : earliest;
    }, null);

    // Check var1 for an earlier first_pickup_time
    let addressWithEarlierTime = null;

    for (const entry of vehicleTrips) {
      for (const trip of entry.trips) {
        const tripTime = parseTime(trip.first_pickup_time);
        if (tripTime.isBefore(earliestVar2Time)) {
          addressWithEarlierTime = trip.last_dropoff_address;
          break;
        }
      }
    }
    const anchor = addressWithEarlierTime || null;
    setAnchorLocation(anchor);
    handleGetOptimizedTrips(anchor);
  };

  // structure the booking list to extract
  // bookings on its own trip
  // all address should have one booking on them
  const fixBookingData = (bookingsData) => {
    const newBookings = [];
    bookingsData.forEach((bookings) => {
      if (bookings.bookings.length > 1) {
        bookings.bookings.forEach((booking) => {
          newBookings.push({
            ...bookings,
            bookings: [booking],
            isMoved: true,
          });
        });
      } else {
        newBookings.push(bookings);
      }
    });

    return newBookings;
  };

  const handleGetOptimizedTrips = async (anchor) => {
    try {
      setSpinner();

      const params = {
        bookings: bookings.map(({ id }) => id),
        shuttle_anchor_location: anchor,
        shuttle_id: driver.shuttle_id,
      };

      const resp = await getOptimizedMultipleFlexTrip(params);

      if (resp.status === 'success') {
        const newBookings = fixBookingData(resp.data?.stop_order);
        setStopOrder(newBookings);
        setView('tripOrder');
      } else {
        throw Error(resp.message.message);
      }
    } catch (e) {
      console.error(e);
    }
    unsetSpinner();
  };

  const handleGetOptimizedTravelRoute = async (orderStopOrder, orderedBookings, changed = false) => {
    try {
      setSpinner();

      const params = {
        stop_order: changed ? combineBookingsByAddress(orderStopOrder) : stopOrder,
        shuttle_id: driver.shuttle_id,
        shuttle_anchor_location: anchorLocation,
      };
      const resp = await getOptimizedRouteTravelData(params);
      if (resp.status === 'success') {
        const newBookings = fixBookingData(resp.data?.stop_order);
        if (changed) {
          setStopOrder([...orderedBookings]);
        }

        setHasChange(changed);
        setOptimizedTime({
          ...resp.data,
          stop_order: newBookings,
        });
        setView('optimizeTrip');
      } else {
        throw Error(resp.message.message);
      }
    } catch (error) {
      console.error(error);
    }

    unsetSpinner();
  };

  useEffect(() => {
    lookForTripBookings();

    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <div>
        {view === 'tripOrder' && (
          <TripReorder
            close={handleClose}
            hasChanged={hasChanged}
            bookings={stopOrder}
            handleNext={handleGetOptimizedTravelRoute}
          />
        )}
        {view === 'optimizeTrip' && (
          <OptimizeTrip
            programId={programId}
            close={handleClose}
            optimizedTime={optimizedTime}
            handelBack={() => setView('tripOrder')}
            driver={driver}
            setSpinner={setSpinner}
            unsetSpinner={unsetSpinner}
            dispatchDate={dispatchDate}
            updateBookingAfterSharedRide={updateBookingAfterSharedRide}
          />
        )}
      </div>
    </div>
  );
};

export default MultiLoad;
